#gallery {
    header {
        background-image: url('/images/redesign-website/gallery/hp-galeria.png'); 
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100%;
    }
    .banner-bottom {
        width: 100%; 
        height: 100px;   
        position: absolute;
        top: 92%;
        transform: translate(0%, -50%);
        -webkit-transform: translate(0%, -50%);
        line-height: 300%; 
    }
    .banner-content {
        font-size: 2rem; 
        margin-left: 1.5rem; 
        vertical-align: middle;
    }
    .banner-opacity {
        opacity: 0.4;
        background-color: #000000;
        height: 100%;
    }
}