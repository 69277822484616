// Setup body height
.bh-contact {
  height: 1500px;
}
// Page general
.page-cover-contact {
  background-image: url(../images/pages/contacto-bg.png);
  background-size: cover;
  height: 850px;
}
// Contact page

.page-contact {

  .panel-plain {
    margin-top: 80px;
    border-radius: 0;
    border: solid 1px #e6e6e6;
    background-color: #FFFFFF;
    height: 700px;

    .contact-title {
      font-size: 28px;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 1.4px;
      color: #f9756c;
      text-align: center;
    }

    .contact-info {
      font-size: 16px;
      font-weight: 500;
      color: #606060;
      .icon-ion {
        color: #b7b7b7;
        font-size: 18px;
        margin-right: 5px;
      }
    }

    .contact-form {
      padding: 0 10px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}
