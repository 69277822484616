footer {
    .footer {
        padding: 10rem 8rem 8rem !important;
        background-image: $salmon;
    }
    .footer, a {
        color: $white;
    }
    .copyright {
        padding: 1rem;
    }
    .copyright a, copyright p {
        color: #797979;
    }
    .logo-footer {
        width: 36px;
        margin-bottom: 2rem;
    }
}
