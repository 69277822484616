.partner__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;

    &--img {
        flex-basis: 50%;
        padding: 15px;

        @media (min-width: 768px) {
            flex-basis: 25%;
        }
    }

    &__img {
        width: 100%;
    }
}