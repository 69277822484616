// Setup body height
.bh-proposito {
  height: 1900px;
}
// Proposito
.page-proposito {
  .main-title {
    margin-top: 320px;
    text-align: center;
    .title {
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 1.4px;
      color: #606060;
    }
    .subtitle {
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 1.4px;
      color: #f9756c;
    }
  }
  .timeline {

    .timeline-pictures {
      margin-top: 50px;

      img {
        opacity: 0.6;
      }
      .image-bordered {
        opacity: 1;
      }
    }

    .timeline-navigator {

      margin-top: 50px;

      .timeline-navi-container {
        list-style: none;
        position: relative;
        padding: 20px 0px 20px 0px;
        display: inline-block;
        width: 100%;

        &:before {
          height: 3px;
          top: auto;
          bottom: 33px;
          left: 30px;
          right: 0;
          width: 100%;
          margin-bottom: 20px;
          position: absolute;
          content: " ";
          background-color: #f9756c;
          margin-left: -1.5px;
        }

        .timeline-navigator-item {
          display: table-cell;
          height: 50px;
          //width: 20%;
          min-width: 320px;
          float: none !important;
          padding-left: 0px;
          padding-right: 20px;
          margin: 0 auto;
          vertical-align: bottom;

          .timeline-badge {
            font-size: 40px;
            color: #f9756c;

            .ion-record {
              cursor: pointer;
            }
          }
        }
      }

    }
    .timeline-info {
      text-align: center;
      margin-top: 50px;

      .title {
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 2.2px;
        color: #f9756c;
      }
      .date {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.2px;
        color: #606060;
        margin: 20px 0;
      }
      .description {
        text-align: justify;
        font-size: 14px;
        font-weight: 500;
        color: #606060;
      }
    }
  }
}
