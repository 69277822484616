// Setup body height
.bh-our-offer {
  height: 1600px;
}
// Others
footer {
  position: relative;
}
#page {
  margin-bottom: 30px;
}
// Nuestra oferta
.submenu-options {
  text-align: center;

  ul li {
    margin: 0px 20px;
  }

  ul li a {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #606060;

    &:hover {
      text-decoration: none;
      color: #f9756c;
    }
    &.current {
      color: #f9756c;
    }
  }
}
.option-content {
  margin-top: 50px;

  .main-image {
    padding-right: 0;
  }

  .description {
    padding-left: 0;
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    background-color: white;
    height: 698px;

    .option-title {
      margin-top: 130px;
      font-size: 26px;
      font-weight: 500;
      line-height: 1.36;
      letter-spacing: 1.4px;
      text-align: center;
      color: #f9756c;
    }
    .option-summary {
      width: 480px;
      font-size: 16px;
      font-weight: 500;
      text-align: justify;
      color: #606060;
      padding-left: 50px;
    }
    .option-features {
      .ul-features {
        list-style-type: none;
        margin-left: 15px;

        .li-feature {
          font-size: 16px;
          font-weight: 500;
          color: #606060;
          padding: 10px 0;
        }
        .ion-android-checkmark-circle {
          font-size: 24px;
          margin-right: 25px;
        }
      }
    }
    .buttons-links {
      margin-top: 30px;
      margin-left: 25px;
    }
  }
}
