* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

//REDESIGN - NAV
.topnav {
   overflow: hidden;
   padding: 1rem 2rem;
   width: 100%;
   position: fixed;
   z-index: 1000;
   background-color: #fff;
   border: solid 1px #e6e6e6; 
   top: 0 !important;
}

.topnav a {
   float: left;
   display: block;
   color: #606060 !important;
   text-align: center;
   padding: 14px 16px;
   text-decoration: none;
   font-size: 1.6rem;
   vertical-align: text-center;
}

.topnav a:not(:first-child) {
    margin-top: 1rem;
}

.topnav .icon {
  display: none;
}
.right{
    float: right !important;
}

.center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  line-height: 300%;
}

@media screen and (max-width: 1199px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right !important;
    display: block !important;
  }
}

@media screen and (max-width: 1199px) {
  .right{
    float: none;
  }
  .topnav.responsive {
    position: fixed;
    background-color: #082533 !important;
    width: 100%;
    height: 100vh;
    overflow: auto
}
  .topnav.responsive .icon {
    position: absolute;
    left: 40%;
    top: 0;
  }
  .topnav.responsive a {
    float: none !important;
    display: block;
    width: 100%;
    color: #fff !important;
  }

  .topnav.responsive img {
    width: 114px !important;
  }

  .container-icons div img {
    width: 100%;
  }
  .container-icons div p {
    font-size: 1rem;
  }
  .topnav a:hover {
    border-bottom: none !important; 
  }
}
.letter-fotec {
  color: $fotec-color;
}

.white-letter {
  color: $white;
}
.btn-fotec {
  background: $salmon-fotec;
  color: $white;
  border-radius: 8px
}
.btn-fotec-white {
  color: $primaryColor;
  background-color: white;
  border-color: lighten($primaryColor, 70%);
}
.btn-primary-outline {
    color: $primaryColor;
    border-color: $primaryColor;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.p-top-100{
  padding: 100px 0;
}
.m-top-50{
  margin-top: 50px;
}
.dropdown-register:hover,
.dropdown-register:active {
  color: #f5f5f5 !important;
  background-color: #fdd1cf !important;
}
.dropdown-menu>li>a {
  color:#428bca;
}
.dropdown ul.dropdown-menu {
  border-radius:4px;
  box-shadow:none;
  margin-top:20px;
  width:300px;
}
.dropdown ul.dropdown-menu:before {
  content: "";
  border-bottom: 10px solid #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 16px;
  z-index: 10;
}
.dropdown ul.dropdown-menu:after {
  content: "";
  border-bottom: 12px solid #ccc;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  position: absolute;
  top: -12px;
  right: 14px;
  z-index: 9;
}

//------Spinner
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.lds-spinner {
  position: relative;
}
.lds-spinner div {
  left: 94px;
  top: 48px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: $primaryColor;
  width: 12px;
  height: 24px;
  border-radius: 40%;
  -webkit-transform-origin: 6px 52px;
  transform-origin: 6px 52px;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-spinner {
  width: 40px !important;
  height: 40px !important;
  -webkit-transform: translate(-20px, -20px) scale(0.2) translate(20px, 20px);
  transform: translate(-20px, -20px) scale(0.2) translate(20px, 20px);
}
  
//for no padding cols
.row-min-padding {
  [class*="col-"] {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

// -> vue transitions
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.panel {
  margin-left: 15px;
}