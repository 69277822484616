// Setup body height
.bh-prices {
  height: 1600px;
}
// Page general
.page-cover-prices {
  background-image: url(../images/pages/precios-bg.png);
  background-size: cover;
  height: 930px;
}
// Prices
.page-prices {

  .main-title {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1.4px;
    text-align: center;
    color: #606060;
    margin-top: 160px;

    .highlighted, small {
      color: #f9756c;
    }
  }

  .prices-table {

    .iva-included {
      font-size: 12px;
      text-align: center;
      color: rgba(96, 96, 96, 0.87);
    }

    .price-item {
      position: relative;
      width: 100%;
      margin: auto;
      padding:20px 0px;
      background-color: #ffffff;
      border: solid 1px #e6e6e6;
      height: 420px;
      text-align: center;

      .highlighted {
        color: #f9756c;
      }

      .price-item-title {
        font-size: 20px;
        font-weight: 500;
        color: #606060;
      }

      .price-from {
        font-size: 12px;
        font-weight: 500;
        color: #b7b7b7;
      }

      .price-cost {
        font-size: 40px;
        font-weight: normal;
        letter-spacing: -3.5px;
        color: #f9756c;
      }

      &.featured {
        height: 460px;
        top: -20px;

        .see-examples {
          color: #f9756c;
        }
      }

      .see-examples {
        font-size: 12px;
        margin-top: 10px;
        color: rgba(96, 96, 96, 0.87);
      }
    }
  }
}


