// Setup body height
.bh-join {
  height: 1500px;
}
// Page general
.page-cover-join {
  //background-image: url(../images/home/hp-bg.png);
  background-size: cover;
  height: 850px;
}
.page-cover-join-index {
  background-image: url(../join/reclutamiento-bg.png);
  background-size: cover;
  height: 850px;
}
// Recruitment form
.panel-600 {
  height: 576px !important;
}
.panel-plain {
  .panel-body {
    padding: 15px 0;
  }
  .step-container {
    padding-left: 20px;
    height: 50px;
    border-bottom: 1px solid #e7e7e7;

    .step-box {
      width: 255px;
      float: left;

      .step-title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #f9756c;
      }

      .step-title-disabled {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: #e7e7e7;
      }
    }
  }

  .join-data {
    .row {
      margin-top: 25px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .btn-group  {
    .active {
      color: #ffffff;
      background-color: $primaryColor;
      border-color: #ffffff;
    }
  }

  .upload-area {
    padding: 15px;
    margin: 20px 15px 0 15px;
    border: 1px dashed #d4d4d4;
    background-color: #ffffff;
    border-radius: 0;

    .upload-logo {
      margin-top: 20px;
      text-align: center;
    }

    .upload-instructions {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #606060;

      .note-2 {
        font-size: 14px;
        color: #b7b7b7;
      }
    }
  }
}
