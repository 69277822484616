// Setup body height
.bh-gallery {
  height: 1400px;
}
// Others
footer {
  position: relative;
}
#page {
  margin-bottom: 30px;
}
// Galería
.submenu-options {
  text-align: center;
  margin-top: 50px;

  ul li {
    margin: 0px 20px;
  }

  ul li a {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #606060;

    &:hover {
      text-decoration: none;
      color: #f9756c;
    }
    &.current {
      color: #f9756c;
    }
  }
}
.submenu-options-children {
  text-align: center;
  margin-bottom: 50px;
}
.slider-gallery {
  margin-top: 50px;

  .carousel-inner {
    height: 400px;
  }

  .carousel-fade .carousel-control {
    z-index: 2; }

  .carousel-indicators {
    text-align: center;
  }

  .carousel-indicators li {
    width: 20px;
    background: rgba(0, 0, 0, 0.2);
  }

  .carousel-indicators .active {
    width: 50px;
    height: 10px;
    background: #f9756c;
    border-color: #f9756c;
  }

  .ion-android-arrow-dropleft-circle {
    top: 160px;
    position: absolute;
    left: 20px;
  }
  .ion-android-arrow-dropright-circle {
    top: 160px;
    position: absolute;
    right: 20px;
  }
}
