// Fonts
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600);

// Font Awesome
@import "resources/assets/font-awesome/scss/font-awesome";

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Sections
@import "general";
@import "page-our-offer";
@import "page-proposito";
@import "page-gallery";
@import "page-prices";
@import "page-contact";
@import "page-partners";
@import "login";
@import "join";
@import "footer";
@import "home";
@import "vertical";
@import "gallery";
