/* REDESIGN
**********************************************************/

#home {
    .hires {
        background-image: $salmon;
        padding-bottom: 8rem;
     }

    .hires {
        div {
            padding: 1rem;
            h2 {
                padding: 5rem 0 5rem;
            }
        }
     }

    .content {
        width: 100%;
    }
    .bg-hero {
        height: 100vh;
        width: 100%;
        background-image: url('/images/redesign-website/home/home-image-fotec.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .subtitle {
        font-size: 2rem;
        text-align: center;
    }
    .container-icons {
        margin-top: 2.5rem;
    }
    .container-icons div img {
        width: 100px;
    }
    .container-icons div p {
        display: block;
        margin-top: 2rem;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2px;
        text-align: center;
        color: #606060;
    }
    .paragraph {
        font-size: 14px;
        color: #606060;
        margin: 2rem 0 2rem;
    }
    .white {
        background-color: #fff;
    }
    .white-letter {
        color: #fff;
    }
    .bg-join {
        background-image: url('/images/redesign-website/home/bg-join.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10rem 0 10rem;
        margin: 0px !important;
    }
    .button-join {
        padding: 10px 10px; 
        color: #fff;
        text-align: center;
        border-radius: 10px;
    }
    .button-join:hover {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
    }

    .footer {
        padding: 10rem 8rem 8rem;
    }
    .subtext {
        font-size: 12px;
        color: #ffffff;
        opacity: 0.6;
    }
    input[type="text"] {
         width: 100%; 
         box-sizing: border-box;
         -webkit-box-sizing:border-box;
         -moz-box-sizing: border-box;
    }
    a:hover {
        color: #fff;
        text-decoration: none;
    }
    .container-networks a:not(:first-child) {
        margin-left: 2rem;
    }
    .copyright {
        background-color: #fff;
        padding: 2rem;
    }
    .topnav {
       background: transparent !important;
       border: none !important;
    }

    .topnav a {
       color: #fff !important;
    }

    .topnav a:not(:first-child) {
        margin-top: 1rem;
    }

    .topnav .icon {
      display: none;
    }

    .topnav-scroll {
       background-color: #fff !important;
       border: solid 1px #e6e6e6 !important;      
        
    }
    .topnav-scroll a{
        color: #606060 !important; 
    }

    .topnav-scroll .white {
         color: #fff; 
    }

    .topnav-scroll a:hover {
        border-bottom: 2px solid #606060 !important;
    }

    
    br {
        padding: 1rem !important;
    }
    .active-fotec {
        color: #EC7B8F;
    }
    .right{
        float: right !important;
    }

    .topnav.responsive a {
        color: #fff !important;
    }
    .bottom-space-1 {
        margin-bottom: 1rem;
    }
    .bottom-space-5 {
        margin-bottom: 5rem;
    }
    .arrows {
        margin-top: 2.5rem;
    }
    #customers {
        background-color: #f9f9f9; 
        padding-bottom: 8rem;
    }
    #customers  h2 {
        padding: 50px 0 50px;
    }
    #customers > div > div {
        padding: 1rem;
    }
    h2 {
        padding: 1rem;
    }
    #benefits {
        padding: 8rem 0 10rem;
    }
    .social-networks {
        position: absolute;
        right: -3.3%;
        top: 59%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        letter-spacing: 0.1em;
        color: #fff !important;
    }
    .line-socialNetworks {
        width: 40px; 
        display: inline-block; 
        text-align: center; 
        margin-bottom: 5px; 
        margin-left: 1rem
    }
    .rotate90 {
       -webkit-transform: rotate(90deg) !important;
       -moz-transform: rotate(90deg) !important; 
    }
    .rotate-90 {
       -webkit-transform: rotate(-90deg) !important;
       -moz-transform: rotate(-90deg) !important; 
       margin-left: 2rem;
    }
    .verticals-content {
        padding: 2rem;
    }

    @media screen and (max-width: 550px) {
        .bg-join {
            padding: 5rem;
        }
    }
    @media screen and (max-width: 1061px) {
      .topnav.responsive {
        background-color: #082533 !important;
        color: #fff !important;
      }
      .topnav a:hover {
        border-bottom: none !important; 
      }
      .items {
        text-align: center;
        padding: 0rem !important;
      }
      .container-icons {
        padding: 2rem;
      }
      .container-icons img{
        width: 64px !important;
      }
      .container-icons div p{
        font-size: 12px;
      }
      #benefits {
        padding: 3rem 0 5rem;
      }
      h1 {
        font-size: 2.5rem;
      }
    }
    .row {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
}