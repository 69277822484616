// All vertical pages
.vertical-container {
	.container-fluid{
        padding: 0;
    }
    .flex-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

    }
    .button-fotec {
        background-image: linear-gradient(283deg, #ff72aa, #f9756c);
        padding: 10px 12px;
        border-radius: 8px;
        color: #fff;
    }
    .banner {
    	margin-top: 100px; 
    	padding: 1.5rem 3rem 3rem; 
    	background-color: #f9756c; 
        color: $white;
    }
    .banner-title {
        margin-top: -20%; 
        font-size: 3em; 
    }
    .banner-description {
        font-size: 1.3em
    }
    .vertical-title {
        font-size: 28px; 
        margin-top: 5rem; 
        margin-bottom: 2rem;
    }
    .vertical-text {
        font-size: 1.8rem;
    }
    .btn-redirec-register {
        width: 200px; 
        display: inline-block;
    }
    .content-description {
        margin: 2rem; 
        padding: 3em 2em
    }
    .prices {
        font-size: 21px
    }
    .padding-2 {
        padding: 2rem;
    }
    .mt-2 {
        margin-top: 2em;
    }
    #prev {
    	left: 0;
        top: 45%;
        right: 0;
        left: 5%;
        bottom: 0;
        position: absolute;
        color: #fff
    }
    #next {
   		left: 0;
        top: 45%;
        right: 0;
        left: 80%;
        bottom: 0;
        position: absolute;
        color: #fff
    }
    .banner p {
    	margin-top: 2.5rem;
    } 
    .gallery-content {
    	padding: 2rem 5rem;
    }
    .testimonials {
        background-color:  #f9f9f9; 
        padding: 2rem 0 6rem
    }
    .testimonials-title {
        padding: 2rem;
    }
    .testimonial-container {
        padding: 0 3rem;
    }
    .testimonial-content {
        margin: 1rem; 
        padding: 3rem 2rem; 
        background-color: #fff
    }
    @media screen and (max-width: 700px) {
        .banner-title {
            margin-top: -20%; 
            font-size: 2em; 
        }
        .banner-description {
            font-size: 1em
        }
        .banner-21 {
            font-size: 1.6em;
            margin-bottom: 1rem;
        }
        .content-description {
            margin: 0rem;
            padding: 0em 1em;
        }
        .gallery-content{
            padding: 0em 1em !important;
        }
        .iframe {
            width: 100% !important;
            height: 260px !important;
        }
    }
}