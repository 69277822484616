// Fotec custom colors
$primaryColor: #F9756C;
$secondaryColor: #072533;
$constrantColor: #FFFFFF;

$primaryText: #606060;
$secondaryText: #B7B7B7;
$constrantText: lighten($constrantColor, 60%);

$primayOutline: #E6E6E6;

// Body
$body-bg: #ffffff; //#f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #F9756C; //#3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Poppins", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: $primaryText;

// Navbar
$navbar-height: 72px;
$navbar-default-toggle-hover-bg: #e7e7e7;
$navbar-default-toggle-icon-bar-bg: #e7e7e7;
$navbar-default-toggle-border-color: #e7e7e7;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #add8e6; //#fff;
$panel-default-text: $body-bg;

// Pills
$nav-pills-active-link-hover-bg: #000;


//redesign web-site
$salmon: linear-gradient(283deg, #ff72aa, #fc7390 42%, #f9756c);
$salmon-fotec: #f9756c;

//redesign web-site Typography
$white: #fff;
$fotec-color: #EC7B8F; 
